.Sponsors {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 75%;
  justify-content: space-evenly;
}

.Sponsors img {
  margin-left: 20px;
}