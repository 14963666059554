.importParentContainer {
  width: 100%;
}

.tableContainer {
  overflow-x: scroll;
  width: 100%;
}

.tableContainer table {
  margin: 0 0 40px 0
}

.tableContainer th {
  background-color: grey;
  color: #ffffff;
  border: 1px solid #bbb;
  padding: 6px 12px
}

.tableContainer td {
  padding: 3px 3px 0 0
}

.tableContainer td, th {
  /* border: 1px solid #dddddd;
  text-align: left;
  padding: 8px; */
}

.tableContainer tr:nth-child(odd) {
  background-color: #e9e9e9
}