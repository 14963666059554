.primary {
  text-align: left;
  display: inline-block;
  padding: 0 2em;
  vertical-align: top;
  border-right: 1px dashed #ccc;
}

.secondary {
  text-align: left;
  display: inline-block;
  padding: 0 2em;
  vertical-align: top;
}

input[type="text"],input[type="password"],input[type="email"],textarea {
  border: 1px solid #bbb;
  width: 14em;
  margin-bottom: 1em;
  padding: 0.4em 0.6em;
  max-width: 100%
}

textarea {
  resize: none;
}