.imagesContainer {
  border:1px solid black;
  padding: 10px;
}

.image {
  padding: 5px;
}

.uploadContainer {
  border:1px solid black;
  padding: 10px;
}

.mainImageContainer {
  border:1px solid black;
  padding: 10px;
}

.imageInputLabel {
  margin-left: 20px;
}

.sponsorImagesContainer {
  border:1px solid black;
  padding: 10px;
}

.sponsorImagesContainerButton {
  margin-top: 20px;
  width: 50%;
}

.imageWithButton {
  display: inline-grid;
  margin-top: 10px;
}

.mainImageWithButtonButton {
  width: 50%;
}