.updateAttendeesPage table {
  margin: 0 0 40px 0
}

.updateAttendeesPage th {
  background-color: grey;
  color: #ffffff;
  border: 1px solid #bbb;
  padding: 6px 12px
}

.updateAttendeesPage td {
  padding: 3px 3px 0 0
}

.attendupdateAttendeesPageeesPage td, th {
  /* border: 1px solid #dddddd;
  text-align: left;
  padding: 8px; */
}

.updateAttendeesPage tr:nth-child(odd) {
  background-color: #e9e9e9
}