.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.App > * {
  margin-top: 20px;
}

.App img {
  margin-top: unset;
}

.watermark {
  position: fixed;
  bottom:0;
  right:5px;
  font-size: x-small;
  opacity: 0.25;
}